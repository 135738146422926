import React, { Component } from "react";
import { StyleSheet, View, TextInput, Text, TouchableOpacity } from "react-native";
import { connect } from "react-redux";
import NewIssue from "../dialogs/newIssue";
import { searchInventoryTypes } from "../../actions/records";
import Swal from "sweetalert2";
import colors from "../../constants/colors";
import DropDown from "../widgets/dropdownMultiple";

class SearchIngredientsByLot extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.initial ? props.initial : "",
            searchText: typeof props.initial == "string" ? props.initial : "",
            height: 30,
            items: [],
            wasEdited: false,
            options: props.options ? props.options : [],
            lotsSelected: props.initial ? props.initial : [],
            openList: false
        };
    }
    componentWillReceiveProps(nextProps) {
        const { isRecipe, defaultLabel, position } = this.props;
        if (nextProps.initial != this.props.initial) {
            if (defaultLabel == "lotcode" && isRecipe) {
                this.setState({ lotsSelected: nextProps.initial });
            } else
                this.setState({ value: nextProps.initial });
        }
        if (nextProps.options != this.props.options) {
            this.setState({ options: nextProps.options });
        }
        if (nextProps.reset != this.props.reset) {
            if (defaultLabel == "lotcode" && isRecipe) {
                this.setState({ lotsSelected: nextProps.initial });
            } else
                this.setState({ value: nextProps.initial });
        }
    }
    searchItems(val) {
        const { type, searchBy, isRecipe, onSelectItem, options } = this.props;
        const { openList } = this.state;
        if (val) {
            if (searchBy == 'lot') {
                if (isRecipe) {
                    if (options) {
                        var filtered = options.filter((option) => {
                            if (option?.lot) {
                                return option.lot.toLowerCase().includes(val.toLowerCase());
                            }
                        });
                        this.setState({ options: filtered, openList: filtered?.length <= 0 ? false : true },()=>{
                            if(filtered?.length <= 0){
                                onSelectItem(val, true);
                            }
                        });
                    }
                }
            }
        } else this.setState({ items: [], options: this.props.options });
    }
    alert() {
        Swal.fire({
            text: "This item was already selected, please choose another one",
            confirmButtonText: "Ok",
            confirmButtonColor: colors.orange,
            backdrop: false
        });
    }
    onSelectedItemsChange(lotsSelected) {
        const { onSelectItem, initial } = this.props;
        this.setState({ lotsSelected });
        if(typeof initial == "string"){
            this.setState({ searchText: "" });
        }
        onSelectItem(lotsSelected, true);
    }
    alertConfirm(msg, conf) {
        Swal.fire({
            text: msg,
            confirmButtonText: conf,
            confirmButtonColor: '#0B4DA0',
            backdrop: false
        });
    }
    render() {
        const { value, items, wasEdited, lotsSelected, options, openList, searchText } = this.state;
        const { defaultComponent, onSelectItem, searchBy, itemInvoice_id, submission, position, isRecipe, isInvoice, isInventoryTransfer, width, openDropDown, backgroundColor, isLast } = this.props;
        return <View>
            {
                isRecipe && searchBy == 'lot' ?
                    <TextInput
                        value={searchText}
                        editable={!defaultComponent}
                        onContentSizeChange={(event) => {
                            if (event.nativeEvent.contentSize.height == 0) {
                                if (this.props.placeholder == '') {
                                    this.setState({ height: 30 })
                                }
                                this.setState({ height: event.nativeEvent.contentSize.height });
                            } else {
                                setTimeout(() => {
                                    this.setState({ height: event.nativeEvent.contentSize.height });
                                }, 5)
                            }
                        }}
                        style={styles.input, {
                            borderColor: "#e7e7e7",
                            borderRadius: 5,
                            borderWidth: 1,
                            color: "#424242",
                            fontFamily: "Roboto",
                            fontSize: 20,
                            fontWeight: "400",
                            padding: 5,
                            height: this.state.height,
                            fontStyle: this.state.value ? this.state.value.length == 0 ? 'italic' : 'normal' : 'normal',
                            overflow: 'visible',
                        }}
                        multiline={true}
                        placeholder={options?.length > 0 ? "Enter a lot to search" : ""}
                        placeholderTextColor={'rgb(0, 47, 108)'}
                        onChangeText={(val) => {
                            if (val == '' && this.props.placeholder == '') {
                                this.setState({ searchText: val, height: 30, wasEdited: true });
                            } else {
                                this.setState({ searchText: val, wasEdited: true });
                            }
                            this.searchItems(val);
                        }}
                    /> : null
            }

            {
                isRecipe && searchBy == 'lot' && (options?.length > 0 || (typeof lotsSelected == "object" && lotsSelected?.length > 0)) ?
                    <DropDown
                        initialOption={lotsSelected} 
                        updated={lotsSelected}
                        reset={this.props.reset}
                        options={options}
                        openList={openList}
                        defaultComponent={defaultComponent}
                        defaultLabel={"No Lot"}
                        allLabel={"FIFO"}
                        onSelect={(lots) => {
                            this.onSelectedItemsChange(lots);
                        }}
                        isRecipe={false} 
                        // isRecipe={(isInvoice || isInventoryTransfer) ? false : isRecipe}
                        width={width}
                        openDropDown={openDropDown}
                        backgroundColor={backgroundColor}
                        isLast={isLast}
                    />
                    : null
            }
            {
                items?.length > 0 &&
                items.map(item => {
                    return <div style={styles.row}>
                        <div>
                            {searchBy == 'lot' ? item?.lot : item?.item}
                        </div>
                        <div>
                            <button style={styles.rowBtn} onClick={() => {
                                if (searchBy == 'lot') {
                                    onSelectItem(item, true);
                                } else {
                                    if (submission[itemInvoice_id]) {
                                        var exists = submission[itemInvoice_id].filter(sub => sub == item?.lot);
                                        if (exists.length > 0 && submission[itemInvoice_id][position] != item?.lot) {
                                            this.alert();
                                            return;
                                        }
                                    }
                                    var newItem = item;
                                    newItem.lotSelected = ["default_lot"];
                                    onSelectItem(item, true);
                                }
                                this.setState({ items: [], value: searchBy == 'lot' ? item?.lot : item?.item, wasEdited: false });
                            }}>+</button>
                        </div>

                    </div>
                })
            }
            {
                searchText && options?.length <= 0 && wasEdited && <View>
                    <Text>No lots were found</Text>
                </View>
            }

        </View>
    }
}

export default connect((state, ownProps = {}) => ({
    isLoading: state.forms.getIn(["form", "status"]) === "loading",
}), (dispatch, props) => ({
    searchInventoryTypes: (formid, saveAsTemplate, saveAsForm, callback) =>
        dispatch(searchInventoryTypes(formid, saveAsTemplate, saveAsForm, callback)),
}))(SearchIngredientsByLot);

const styles = StyleSheet.create({
    container: {
        flexDirection: "column",
        paddingTop: 5,
        flex: 1,
    },
    input: {
        borderColor: "#e7e7e7",
        borderRadius: 5,
        borderWidth: 1,
        color: "#424242",
        fontFamily: "Roboto",
        fontSize: 22,
        fontWeight: "400",
        paddingLeft: 10,
    },
    label: {
        fontSize: 20,
        color: '#939598',
        fontWeight: '300',
        fontFamily: 'Roboto',

    },
    row: {
        display: "grid",
        padding: 5,
        width: "100%",
        gridTemplateColumns: "90% 10%",
        fontFamily: "Roboto",
        fontSize: 18
    },
    rowBtn: {
        color: 'rgb(244, 152, 30)',
        border: '2px solid',
        borderRadius: 4
    }
});