import React, { Component } from "react";
import { StyleSheet, View, TextInput, Text, TouchableOpacity } from "react-native";
import { connect } from "react-redux";
import NewIssue from "../dialogs/newIssue";
import { searchInventoryTypes } from "../../actions/records";
import Swal from "sweetalert2";
import colors from "../../constants/colors";
import DropDown from "../widgets/dropdownMultiple";

class SearchInventory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // value: ((props.defaultLabel == "lotcode_finished" || props.defaultLabel == "lotcode_wip") && props.isShipmentLog) ? "" : props.initial ? props.initial : "",
            value: typeof props.initial == "string" ? props.initial : "",
            height: 30,
            items: [],
            wasEdited: false,
            options: props.options ? props.options : [],
            lotsSelected: (props.defaultLabel == "lotcode_finished" || props.defaultLabel == "lotcode_wip" || props.defaultLabel == "lotcode") && props.isShipmentLog && props.initial ? props.initial : [],
            openList: false
        };
    }
    componentWillReceiveProps(nextProps) {
        const { isShipmentLog, defaultLabel, position  } = this.props;
        if (nextProps.initial != this.props.initial){
          if((defaultLabel == "lotcode_finished" || defaultLabel == "lotcode_wip" || defaultLabel == "lotcode") && isShipmentLog){
            this.setState({ lotsSelected: nextProps.initial });
        }else
            this.setState({ value: nextProps.initial });
        }
        if (nextProps.options != this.props.options) {
            this.setState({ options: nextProps.options });
        }
        if(nextProps.reset != this.props.reset){
            if((defaultLabel == "lotcode_finished" || defaultLabel == "lotcode_wip" || defaultLabel == "lotcode") && isShipmentLog){
                this.setState({ lotsSelected: nextProps.initial });
            }else
                this.setState({ value: nextProps.initial });
        }
    }
    searchItems(val) {
        const { type, searchBy, isShipmentLog, showWipSearch, onSelectItem, options } = this.props;
        const { openList } = this.state;
        if(val){
            if(searchBy == 'lot'){
                if(isShipmentLog){
                    if(options){
                        var filtered = options.filter((option)=> {
                            if(option?.lot){
                                return option.lot.toLowerCase().includes(val.toLowerCase());
                            }
                        });
                        this.setState({ options: filtered, openList: filtered?.length <= 0 ? false : true },()=>{
                            if(filtered?.length <= 0){
                                onSelectItem(val, true);
                            }
                        });
                    }
                }else{
                    var data = { page: 1, lot: val, type, fromApp: true, fromRecipe: true };
                    if(showWipSearch){
                        data.name = showWipSearch;
                        data.showWipSearch = true;
                    }
                    this.props.searchInventoryTypes(data, (res) => {
                        this.setState({ items: res?.inventoryTypes });
                    });
                }
            }else{
                this.props.searchInventoryTypes({ page: 1, name: val, type, fromApp: true }, (res) => {
                    this.setState({ items: res?.inventoryTypes });
                });
            }
            
        }else this.setState({ items: [], options: this.props.options });
    }
    alert(){
        Swal.fire({
            text: "This item was already selected, please choose another one",
            confirmButtonText: "Ok",
            confirmButtonColor: colors.orange,
            backdrop: false
        });
    }
    onSelectedItemsChange(lotsSelected){
        const { onSelectItem, initial } = this.props;
        this.setState({ lotsSelected });
        if(typeof initial == "string"){
            this.setState({ value: "" });
        }
        onSelectItem(lotsSelected, true);
    }
    validLots(lots){
        const { id, position, submission } = this.props;
        var lotsSelecteds = submission[id];
        var valid = true;
        if(lots?.length > 0 && typeof lots == "object"){
            lots.map((lotCode)=>{
                if(lotsSelecteds){
                  lotsSelecteds.map((lotselected, idx)=>{
                    if(idx != position && lotselected){
                      lotselected.map((lS)=>{
                        if(lS?.lot == lotCode?.lot){
                          valid = false;
                        }
                      })
                    }
                  })
                }
            });
        }
        return valid;
    }
    alertConfirm(msg, conf) {
        Swal.fire({
            text: msg,
            confirmButtonText: conf,
            confirmButtonColor: '#0B4DA0',
            backdrop: false
        });
    }
    render() {
        const { value, items, wasEdited, lotsSelected, options, openList } = this.state;
        const { defaultComponent, onSelectItem, searchBy, itemInvoice_id, submission, position, isShipmentLog, isInventoryTransfer, width, openDropDown, backgroundColor, isLast } = this.props;
        return <View>
            <TextInput
                value={value}
                editable={!defaultComponent}
                onContentSizeChange={(event) => {
                    if (event.nativeEvent.contentSize.height == 0) {
                        if (this.props.placeholder == '') {
                            this.setState({ height: 30 })
                        }
                        this.setState({ height: event.nativeEvent.contentSize.height });
                    } else {
                        setTimeout(() => {
                            this.setState({ height: event.nativeEvent.contentSize.height });
                        }, 5)
                    }
                }}
                style={styles.input, {
                    borderColor: "#e7e7e7",
                    borderRadius: 5,
                    borderWidth: 1,
                    color: "#424242",
                    fontFamily: "Roboto",
                    fontSize: 20,
                    fontWeight: "400",
                    padding: 5,
                    height: this.state.height,
                    fontStyle: this.state.value ? this.state.value.length == 0 ? 'italic' : 'normal' : 'normal',
                    overflow: 'visible',
                }}
                multiline={true}
                placeholder={searchBy == 'lot' ? "Enter a lot to search" : "Enter an item to search"}
                placeholderTextColor={'rgb(0, 47, 108)'}
                onChangeText={(val) => {
                    if (val == '' && this.props.placeholder == '') {
                        this.setState({ value: val, height: 30, wasEdited: true });
                    } else {
                        this.setState({ value: val, wasEdited: true });
                    }
                    this.searchItems(val);
                }}
            />
            {
                isShipmentLog && searchBy == 'lot' && options?.length > 0 ? 
                <DropDown
                  //initialOption={ typeof options.find(x => x.lot == this.state.lot) === 'undefined'  ? options[0] : {lotsSelected: lotsSelected }}
                  initialOption={lotsSelected}
                  updated={lotsSelected}
                  reset={this.props.reset}
                  options={options}
                  openList={openList}
                  defaultComponent={defaultComponent}
                  defaultLabel={"No Lot"}
                  allLabel={"FIFO"}
                  allValue={["default_lot"]}
                  onSelect={(lots) => {
                    this.onSelectedItemsChange(lots);
                  }}
                  openDropDown={openDropDown}
                  backgroundColor={backgroundColor}
                  isLast={isLast}
                />
                : null
            }
            {
                items?.length > 0 &&
                    items.map(item =>{
                        return <div style={{...styles.row, gridTemplateColumns: width == "mini" ? "80% 20%" : "90% 10%"}}>
                            <div>
                                {searchBy == 'lot' ? item?.lot : item?.item}
                            </div>
                            <div>
                                <button style={styles.rowBtn} onClick={() => {
                                    if(searchBy == 'lot'){
                                        onSelectItem(item, true);
                                    }else{
                                        if(submission[itemInvoice_id]){
                                            var exists = submission[itemInvoice_id].filter(sub => sub == item?.lot);
                                            if(exists.length > 0 && submission[itemInvoice_id][position] != item?.lot){
                                                this.alert();
                                                return;
                                            }
                                        }
                                        var newItem = item;
                                        newItem.lotSelected = ["default_lot"]; 
                                        if(isInventoryTransfer){
                                            onSelectItem(item?.item ? item.item : "", true);
                                        }else{
                                            onSelectItem(item, true);
                                        }
                                    }
                                    this.setState({ items: [], value: searchBy == 'lot' ? item?.lot : item?.item, wasEdited: false });
                                }}>+</button>
                            </div>
                            
                        </div>
                    })
            }
            {
                value && items?.length <= 0 && wasEdited && ((!isShipmentLog && searchBy != 'lot') || (isShipmentLog && searchBy != 'lot')) && <View>
                    <Text>No items were found</Text>
                </View>
            }

        </View>
    }
}

export default connect((state, ownProps = {}) => ({
    isLoading: state.forms.getIn(["form", "status"]) === "loading",
}), (dispatch, props) => ({
    searchInventoryTypes: (formid, saveAsTemplate, saveAsForm, callback) =>
        dispatch(searchInventoryTypes(formid, saveAsTemplate, saveAsForm, callback)),
}))(SearchInventory);

const styles = StyleSheet.create({
    container: {
        flexDirection: "column",
        paddingTop: 5,
        flex: 1,
    },
    input: {
        borderColor: "#e7e7e7",
        borderRadius: 5,
        borderWidth: 1,
        color: "#424242",
        fontFamily: "Roboto",
        fontSize: 22,
        fontWeight: "400",
        paddingLeft: 10,
    },
    label: {
        fontSize: 20,
        color: '#939598',
        fontWeight: '300',
        fontFamily: 'Roboto',

    },
    row:{
        display: "grid",
        padding: 5,
        width: "100%",
        gridTemplateColumns: "90% 10%",
        fontFamily: "Roboto",
        fontSize: 18
    },
    rowBtn:{
        color: 'rgb(244, 152, 30)',
        border: '2px solid',
        borderRadius: 4
    }
});